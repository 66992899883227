import styled, { css } from 'styled-components';
import { Modal } from 'react-bootstrap';
import { CustomDropDown } from '../PeopleHomeV2/styles';
import { SearchButton } from '../AddAttendeesModal/styles'
const MediumFont = 'Rubik-Regular';
const StyledModal = styled(Modal)`
.modal-dialog {
  // position: absolute;
  width: 500px;
  // left: 600px;
  top: 100px;
  border-radius: 6px;
  margin: auto;
  background: #FFFFFF;
  .modal-content {
    position: absolute;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border-radius: 6px;
    outline: 0;
    -webkit-box-shadow: 0 3px 9px rgb(0 0 0 / 50%);
    box-shadow: 0 3px 9px rgb(0 0 0 / 50%);
    width: 100%;
    font-family: Rubik;
    font-style: normal;
    font-weight: 100px;
    font-size: 16px;
    line-height: 30px;
    border-top: white;
    padding: 0px;
    /* color: white; */
}
 .modal-header {
    padding: 0;
    color: white;
    background-color: #25a8e18f;

 }
 .lgHHDV > div > span {
  text-transform: capitalize;
    font-size: 20px;
    font-weight: 900;
    font-family: Rubik-Regular;
    font-style: normal;
    padding-left: 20px;
 }
 .modal-title {
  margin-top: -26px;
 }
 .close {
  float: right;
  font-size: 20px;
  font-weight: 700;
  /* margin: 5px; */
  line-height: 1;
   color: black;
   width: 30px;
    height: 31px;
    padding: 5px;
    margin-top: 8px;
    margin-right: 16px;
    border: 2px solid white;
}
  .modal-body{
    display: flex;
  }
  @media (max-width: 500px){
    width: 100%;
    margin: 30px auto;
  }
}
`;
const ContentWrapper = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  label {
    width: 100%
    font-weight: 700;
  }
  .btn-group {
    width: 100%;
    height: 47px;
    left: 1px;
    .title {

    }
  }

  .dropdown-menu {
    width: 100%;
    height: auto;
    max-height: 146px;
    overflow-x: hidden;
    overflow-y: auto;
    overflow: -moz-scrollbars-none;
    text-align: center;
    ::-webkit-scrollbar {
     width: 5px;
     height: 5px;
   }
    ::-webkit-scrollbar-thumb {
     background: #99999C;
     cursor: pointer;
     border-radius: 2.5px;
   } 

    ::-webkit-scrollbar-track {
     background: #fff;
     border-radius: 2.5px;
   }
    > li {
        > a:  {
          padding: 5px 15px;
        }
        a:hover {
          background-color: #69c2FF;
          color: blue;
          opacity: 0.4;
          border-radius: 3px;    
        }
      }
    > ul {
     width: 100%;
    }
    .right_cross_icon {
      width: 10px;
      height: 20px;
    }
  }
  .dropdown-toggle::after {
    margin-left: 23.5em;
    margin-top:5px;
    color:rgb(0, 92, 135);
  }

  .dropdown-menu > a:hover {
    background-color: rgb(105, 194, 255);
    color: blue;
    opacity: 0.4;
    border-radius: 3px;
  }

  .dropdown-menu > a {
    font-size: 14px;
    padding: 0px 0px;
  }
  
`;
const DownloadButton = styled(SearchButton)`
    background-color: #4CB712;
    width: 100%;
    font-size: 14px;
    border-radius: 8px;
    text-transform: capitalize;
    margin-top: 21px;
    ${({ disabled }) => disabled === true && css`
    opacity: 0.4;
    cursor: not-allowed !important;
  `}
`;
const DropDown = styled(CustomDropDown)`
  background-color: #EDEDED;
  width: 100%;
  box-shadow: none;
  padding: 0px 0px;
  padding-top: 4px;
  .title {
    overflow: auto;
    text-overflow: ellipsis;
    font-family: ${MediumFont};
    float: left;
    max-width:250px;
    white-space:nowrap;
  }
  &:active, &:hover, &:focus, &:active:focus {
    outline: none;
    background-color: #EDEDED;
    color: rgb(51,51,51);
  }
  button{
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
  }
`;

const CustomTitle = styled.div`
width: 100%;
display: flex;
>div:first-child{
 width: 80%;
 padding-left: 25px;
    display: flex;
    margin: auto;
 >span{
  font-family: 'Rubik-Medium';
  font-size: 16px;
  text-transform: uppercase;
  color: white;
 }
}
>div:last-child{
  width: 20%;
  padding: 13px 25px;
  display: flex;
  justify-content: flex-end;
  >img{
  width: 24px;
  height: 24px;
  cursor: pointer;
  }
}
@media (max-width: 500px) {
  padding: 0 10px;
}
`;

const DownloadButtonNew = styled(SearchButton)`
    background-color: #4CB712;
    width: 100%;
    font-size: 14px;
    border-radius: 8px;
    text-transform: capitalize;
    margin-top: 21px;
    opacity: 0.4;
    cursor: not-allowed !important;
`;
export { StyledModal, ContentWrapper, DownloadButton, DropDown, CustomTitle, DownloadButtonNew };