/* eslint-disable no-prototype-builtins */
import React, { Component } from 'react';
import { StyledModal, ContentWrapper, DownloadButton, DropDown, DownloadButtonNew } from './styles';
import { DropdownItem, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import CSVList from "../AttendeesModal/CSVList";
import { connect } from 'react-redux';
import { getEnrolledUsersAPI, getFitnessReport, getCompanyRewardPrograms, getRewardsReport } from "../../redux/actions/companyDashboard";
import { CustomTitle } from './styles';
import Waiting from "../Waiting";

const headers = [
  { label: "First Name", key: "FIRST NAME" },
  { label: "Last Name", key: "LAST NAME" },
  { label: "Email", key: "EMAIL" },
  { label: "Department", key: "DEPARTMENT" },
  { label: "Location", key: "LOCATION" },
  {label:"In Team", key: "TEAM NAME"},
  { label: "HRA", key: "HRA" },
  { label: "Device", key: "DEVICE" },
  { label: "Wellness Goals", key: "WELLNESS GOALS" },
  { label: "Biometric Screening", key: "PHYSICIAN FORM" },
  { label: "Fitness Points", key: "FITNESS POINTS" },
  { label: "Health Data Points", key: "HEALTH_ASSESSMENT_POINTS" },
  { label: "Social Points", key: "SOCIAL POINTS" },
  { label: "Wellness Points", key: "EDUCATION POINTS" },
  { label: "Total Points", key: "TOTAL POINTS" },
  { label: "Events", key: "EVENT_COUNT" },
  { label: "On-Demand Events", key: "WEBINAR_WATCHED" },
  { label: "Events Attended", key: "ATTENDED_EVENT" },
  { label: "Challenges", key: "CHALLENGE_COUNT" },
  { label: "January", key: "MONTHLY_POINTS.January" },
  { label: "February", key: "MONTHLY_POINTS.February" },
  { label: "March", key: "MONTHLY_POINTS.March" },
  { label: "April", key: "MONTHLY_POINTS.April" },
  { label: "May", key: "MONTHLY_POINTS.May" },
  { label: "June", key: "MONTHLY_POINTS.June" },
  { label: "July", key: "MONTHLY_POINTS.July" },
  { label: "August", key: "MONTHLY_POINTS.August" },
  { label: "September", key: "MONTHLY_POINTS.September" },
  { label: "October", key: "MONTHLY_POINTS.October" },
  { label: "November", key: "MONTHLY_POINTS.November" },
  { label: "December", key: "MONTHLY_POINTS.December" },
  { label: "Registered Date", key: "REGISTERED ON" },
];
const headersFitness = [
  { label: "First Name", key: "FIRST NAME" },
  { label: "Last Name", key: "LAST NAME" },
  { label: "Email", key: "EMAIL" },
  { label: "Location", key: "LOCATION" },
  { label: "Steps", key: "STEPS" },
  { label: "Steps Points", key: "STEPS POINT" },
  { label: "Calories ", key: "CALORIES" },
  { label: "Calories Points", key: "CALORIES POINT" },
  { label: "Log Minutes", key: "LOG MINUTES" },
  { label: "Log Points", key: "LOG POINTS" },
  { label: "Monthly Goals", key: "MONTHLY GOALS" },
  { label: "Monthly Goals Points", key: "MONTHLY GOALS POINTS" },
  { label: "Total Fitness Points", key: "TOTAL FITNESS POINTS" },
  { label: "January", key: "MONTHLY_POINTS.January" },
  { label: "February", key: "MONTHLY_POINTS.February" },
  { label: "March", key: "MONTHLY_POINTS.March" },
  { label: "April", key: "MONTHLY_POINTS.April" },
  { label: "May", key: "MONTHLY_POINTS.May" },
  { label: "June", key: "MONTHLY_POINTS.June" },
  { label: "July", key: "MONTHLY_POINTS.July" },
  { label: "August", key: "MONTHLY_POINTS.August" },
  { label: "September", key: "MONTHLY_POINTS.September" },
  { label: "October", key: "MONTHLY_POINTS.October" },
  { label: "November", key: "MONTHLY_POINTS.November" },
  { label: "December", key: "MONTHLY_POINTS.December" },
  { label: "User Type", key: "TYPE" },
  { label: "Device Minutes", key: "DEVICE MINUTES" },
  { label: "Device Minutes Points", key: "DEVICE MINUTES POINTS" },
]

let headersRewards = [];

class EngagementCSVModal extends Component {
  constructor() {
    super();
    this.state = {
      printCSV: false,
      selectedYear: '',
      isloading: false,
      selectedFileType: '',
      selectedTypeId: '',
      featureData: [
        { key: "Engagement", Value: "Engagement", id: '10' },
        { key: "Fitness", Value: "Fitness", id: '11' },
      ],
      yearActive: 0
    }
  }

  componentDidMount() {
    // const { companyId, getCompanyRewardPrograms } = this.props;
    // getCompanyRewardPrograms(companyId, this.state.selectedYear);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.companyId !== this.props.companyId) {
      getCompanyRewardPrograms(this.props.companyId, this.state.selectedYear);
    }
    if (prevProps.companyRewardPrograms !== this.props.companyRewardPrograms || prevProps.companyId !== this.props.companyId) {
      const { companyRewardPrograms } = this.props;
      if (companyRewardPrograms && companyRewardPrograms.length > 0) {
        let dropdownData = [];
        dropdownData = [
          { key: "Engagement", Value: "Engagement", id: '10' },
          { key: "Fitness", Value: "Fitness", id: '11' }
        ];
        companyRewardPrograms.forEach((data) => {
          let object = {
            value: data.reward_name,
            key: data.reward_name,
            id: data.id
          }
          dropdownData.push(object);
        });
        this.setState({ featureData: dropdownData });
      }
      else {
        let dropdownData = [];
        dropdownData = [
          { key: "Engagement", Value: "Engagement", id: '10' },
          { key: "Fitness", Value: "Fitness", id: '11' }
        ];
        this.setState({ featureData: dropdownData });
      }
    }
    if (prevProps.rewardsReport !== this.props.rewardsReport) {
      headersRewards = [];
      headersRewards = [
        { label: "First Name", key: "FIRST NAME" },
        { label: "Last Name", key: "LAST NAME" },
        { label: "Email", key: "EMAIL" },
        { label: "Location", key: "LOCATION" },
        { label: "Department", key: "DEPARTMENT" },
        { label: "Total Points", key: "TOTAL POINTS" },
      ];
      if (this.props.rewardsReport && this.props.rewardsReport.length > 0) {
        let ObjectKeys = Object.keys(this.props.rewardsReport[0]);
        ObjectKeys.forEach((data) => {
          if (data.includes('Level Achieved')) {
            let obj = { label: data, key: data };
            headersRewards.push(obj);
          }
          if (data.includes('Total Earned')) {
            let obj = { label: data, key: data };
            headersRewards.push(obj);
          }
        })
      }

    }

  }
  updateDropdown = (year) => {
    const { getEnrolledUsersAPI, getFitnessReport, getCompanyRewardPrograms } = this.props;
    const { companyId } = this.props;
    const { selectedFileType, selectedTypeId } = this.state;
    getCompanyRewardPrograms(companyId, year);
    selectedFileType === 'Engagement' ? getEnrolledUsersAPI(companyId, year) : selectedFileType === 'Fitness' ? getFitnessReport(companyId, year) : getRewardsReport(selectedTypeId, companyId, year);
    this.setState({ selectedYear: year, yearActive: 1 });
  }

  onChangeDropdown = (list) => {
    const { companyId, getEnrolledUsersAPI, getFitnessReport, getRewardsReport } = this.props;
    if (list.key === "Engagement") {
      getEnrolledUsersAPI(companyId, this.state.selectedYear);
    }
    else if (list.key === "Fitness") {
      getFitnessReport(companyId, this.state.selectedYear);
    }
    else {
      getRewardsReport(list.id, companyId, this.state.selectedYear)
    }
    this.setState({ selectedFileType: list.key, selectedTypeId: list.id, yearActive: 2 });
  }
  downloadEnrolledList = (bool) => {
    const { enrolledUsers, role } = this.props;
    let isEmployeeIdExist = enrolledUsers && enrolledUsers.length > 0 && enrolledUsers[0].hasOwnProperty('EMPLOYEE_ID');
    let isUserTypeExist = enrolledUsers && enrolledUsers.length > 0 && enrolledUsers[0].hasOwnProperty('TYPE');
    if (isEmployeeIdExist) {
      if (bool) {
        headers.unshift({ label: "Employee ID", key: "EMPLOYEE_ID" });
      }
      else {
        headers.shift()
      }
    }
    if (isUserTypeExist) {
      if (bool) {
        headers.push({ label: "User Type", key: "TYPE" });
      }
      else {
        headers.pop()
      }
    }
    if (role === 'ADMIN') {
      if (bool) {
        headers.push({ label: 'Physician Form Data', key: "PHYSICIAN FORM DATA" })
      }
      else {
        headers.pop()
      }
    }
    this.setState({
      printCSV: bool,
      isloading: bool
    })
  };

  downloadFitnessList = (bool) => {
    const { fitnessUsers, rewardsReport } = this.props;
    const { selectedFileType } = this.state;
    let headers = selectedFileType === 'Fitness' ? fitnessUsers : rewardsReport;
    let isEmployeeIdExist = headers && headers.length > 0 && headers[0].hasOwnProperty('EMPLOYEE_ID');
    if (isEmployeeIdExist) {

      if (bool) {
        selectedFileType === 'Fitness' ? headersFitness.unshift({ label: "Employee ID", key: "EMPLOYEE_ID" }) :
          headersRewards.unshift({ label: "Employee ID", key: "EMPLOYEE_ID" });
      }
      else {
        selectedFileType === 'Fitness' ? headersFitness.shift() : headersRewards.shift();
      }
    }
    this.setState({
      printCSV: bool,
      isloading: bool
    })
  };
  render() {
    const { showModal, onHide, fileTitle, yearList, enrolledUsers, csvButton, fitnessUsers, rewardsReport } = this.props;
    const { selectedFileType, featureData, yearActive } = this.state;
    const ShowDropdownStatus = selectedFileType === '' ? true : selectedFileType === 'Engagement' ? (enrolledUsers && enrolledUsers.length > 0) : selectedFileType === 'Fitness' ?
      (fitnessUsers && fitnessUsers.length > 0) : (rewardsReport && rewardsReport.length > 0);
    return (
      <div>
        <StyledModal show={showModal} onHide={() => onHide()}>
          <Modal.Header>
            <CustomTitle style={{background:'#005c87'}}>
              <div>
                <span className="download">Download File</span>
              </div>
              <div className={"closeButton"} onClick={() => onHide()}>
                <img src={'/public/images/NewDashboardV2/new_close_icon.png'} alt={'X'} />
              </div>
            </CustomTitle>
          </Modal.Header>
          <Modal.Body>
            {(csvButton || this.state.isloading) && <Waiting />}
            {
              <ContentWrapper>
                <label className="year" style={{color:"#005c87", marginBottom: "12px"}}>Select Year
                  <DropDown
                    type="button" title={<div className="title" style={{overflow:"visible"}}>{this.state.selectedYear === "" ? "Select" : this.state.selectedYear}</div>}
                    id="year"
                    onSelect={(index, /*indexKey*/) => this.updateDropdown(yearList[index])}
                  >
                    {
                      yearList.map((list, index) => (
                        <DropdownItem key={index} placeholder="Select" eventKey={index} onSelect={() => this.updateDropdown(list)}>{list}</DropdownItem>
                      ))
                    }
                  </DropDown>
                </label>
                {yearActive ? <label className="year" style={{color:"#005c87", marginBottom: "12px"}}>Select Your Report
                  <DropDown className={"download-dropdown"}
                    onSelect={(index, /*indexKey*/) => this.onChangeDropdown(featureData[index])}
                    type="button" title={<div className="title" style={{overflow:"visible"}}>{this.state.selectedFileType === "" ? "Select" : this.state.selectedFileType}</div>
                    }
                    id="year" >

                    {
                      featureData.map((list, index) => (
                        <DropdownItem key={index} placeholder="Select" eventKey={index} onSelect={() => this.onChangeDropdown(list)}>{list.key}</DropdownItem>
                      ))
                    }
                  </DropDown>
                </label> :
                  <label className="year" style={{color:"#005c87", marginBottom: "12px"}}>Select Your Report
                    <DropDown className={"download-dropdown"}
                      type="button" title={<div className="title" style={{overflow:"visible"}}>{this.state.selectedFileType === "" ? "Select" : this.state.selectedFileType}</div>
                      }
                      id="year"  />
                  </label>}
                {ShowDropdownStatus ? null : <ContentWrapper>{selectedFileType === 'Engagement' ? "No enrolled Users for this company" : "No report found"}</ContentWrapper>}

                {yearActive===2?<DownloadButton style={{background:'#005c87'}} disabled={csvButton || !ShowDropdownStatus} onClick={() => { selectedFileType === 'Engagement' ? this.downloadEnrolledList(true) : this.downloadFitnessList(true) }}>Download CSV</DownloadButton>:
                  <DownloadButtonNew style={{background:'#005c87'}}>Download CSV</DownloadButtonNew>}
              </ContentWrapper>
            }
          </Modal.Body>
          {this.state.printCSV &&
            <CSVList Title={`${fileTitle}${selectedFileType === 'Engagement' ? "_Enrolled_Users_" : selectedFileType === 'Fitness' ? "_Fitness_Report_" : `_${selectedFileType}_`}${this.state.selectedYear}`}
              removeCSVData={selectedFileType === 'Engagement' ? this.downloadEnrolledList : this.downloadFitnessList}
              data={selectedFileType === 'Engagement' ? enrolledUsers : selectedFileType === 'Fitness' ? fitnessUsers : rewardsReport} headers={selectedFileType === 'Engagement' ? headers :
                selectedFileType === 'Fitness' ? headersFitness : headersRewards} />
          }
        </StyledModal>
      </div>

    );
  }
}

EngagementCSVModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  fileTitle: PropTypes.string,
  yearList: PropTypes.array,
  enrolledUsers: PropTypes.array,
  fitnessUsers: PropTypes.array,
  getEnrolledUsersAPI: PropTypes.func,
  companyId: PropTypes.number,
  setSelectedYear: PropTypes.func,
  csvButton: PropTypes.bool,
  role: PropTypes.string,
  getFitnessReport: PropTypes.func,
  selectedTabId: PropTypes.number,
  getCompanyRewardPrograms: PropTypes.func,
  companyRewardPrograms: PropTypes.array,
  getEngagementReport: PropTypes.func,
  getRewardsReport: PropTypes.func,
  rewardsReport: PropTypes.array
};
const mapStateToProps = (state) => ({
  enrolledUsers: state.companyDashboard.enrolledUsers,
  fitnessUsers: state.companyDashboard.fitnessUsers,
  companyRewardPrograms: state.companyDashboard.companyRewardPrograms,
  rewardsReport: state.companyDashboard.rewardsReport,
});
const mapDispatchToProps = (dispatch) => ({
  getEnrolledUsersAPI: (id, year) => dispatch(getEnrolledUsersAPI(id, year)),
  getFitnessReport: (id, year) => dispatch(getFitnessReport(id, year)),
  getCompanyRewardPrograms: (id, selectedYear) => dispatch(getCompanyRewardPrograms(id, selectedYear)),
  getRewardsReport: (id, cId, selectedYear) => dispatch(getRewardsReport(id, cId, selectedYear))
});

export default connect(mapStateToProps, mapDispatchToProps)(EngagementCSVModal);
